<template>
  <div id="activities-column">
    <div class="top">
      <img :src="Pic" />
      <div class="introduce">
        <h2>{{ Details.activityName }}</h2>
        <!-- <h2>活动名称</h2> -->
        <div>
          <p>{{ Details.activityDescribe }}</p>
          <!-- <p>1、活动期间全场商品打9.5折。</p>
          <p>
            2、参与活动品类包含:水果蔬菜、肉禽蛋奶、粮油调味、酒水饮料、
            休闲食品、个人护理、化妆品、清洁用品、日用百货等。
          </p>
          <p>3、活动时间2021年10月14日00:00:00至2021年11月11日23:59:59</p>
          <p>4、活动期间全场商品打9.5折。</p>
          <p>
            5、参与活动品类包含:水果蔬菜、肉禽蛋奶、粮油调味、酒水饮料、
            休闲食品、个人护理、化妆品、清洁用品、日用百货等。
          </p>
          <p>6、活动时间2021年10月14日00:00:00至2021年11月11日23:59:59</p> -->
        </div>
      </div>
    </div>
    <div class="content">
      <div class="goods">
        <goods
          v-for="(list, index) in Goods"
          :key="index"
          :information="list"
        />
      </div>
      <el-pagination
        :page-size="25"
        :pager-count="9"
        layout="prev, pager, next"
        :total="total"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import Goods from "@/components/Goods";
export default {
  name: "activities-column",
  components: {
    Goods,
  },
  data() {
    return {
      Details: "",
      Pic: "",
      Goods: "",
      pageNum: 1, //页码
      total: 0,
    };
  },
  created() {
    this.getEventDetails();
    this.getActivityGoods();
  },
  methods: {
    async getEventDetails() {
      const data = await this.$API.activity.getActivity({
        object: {
          id: this.$route.params.id,
        },
        pageNum: 0,
        pageSize: 0,
      });
      this.Details = data.data.data;
      // console.log(this.Details);
      this.Pic = data.data.data.platformPic.split(",")[1];
    },
    async getActivityGoods() {
      const data = await this.$API.activity.getActivityGoods({
        object: {
          id: this.$route.params.id,
        },
        pageNum: 0,
        pageSize: 0,
      });
      this.total = data.data.total;
      this.Goods = data.data.data.map((item) => {
        return {
          ...item,
          filePath: item.filePath.split(",")[0]
        };
      });
    },
    //上一页
    prev() {
      this.pageNum--;
      this.getActivityGoods();
    },
    //下一页
    next() {
      this.pageNum++;
      this.getActivityGoods();
    },
    //分页数字
    current(i) {
      this.pageNum = i;
      this.getActivityGoods();
    },
  },
};
</script>

<style lang="scss" scoped>
#activities-column {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #ffffff;
  border-top: 1px solid #00AEEB;
  padding-top: 30px;
  .top {
    width: 1200px;
    height: 360px;
    position: relative;
    img {
      width: 100%;
      height: 100%;
      transition: all 0.3s;
      opacity: 1;
    }
    .introduce {
      width: 460px;
      height: 280px;
      background: #ffffff;
      border-radius: 4px;
      display: flex;
      position: absolute;
      flex-direction: column;
      top: 40px;
      box-shadow: 0px 0px 10px rgb(0 0 0 / 18%);
      left: 60px;
      // padding: 5px 16px;
      h2 {
        width: 460px;
        height: 48px;
        background: #e1b07b;
        opacity: 1;
        border-radius: 4px 4px 0px 0px;
        font-size: 22px;
        // font-family: Source Han Serif CN;
        // font-weight: bold;
        line-height: 48px;
        text-align: center;
        color: #ffffff;
      }
      div {
        // padding: 5px 6px 5px 18px;
        padding: 5px 16px;
        height: 232px;
        overflow-y: auto;
        p {
          margin: 10px 0;
          font-size: 14px;
          line-height: 19px;
          color: #666666;
        }
      }
    }
  }
  .content {
    width: 1200px;
    margin-top: 30px;
    .goods {
      display: flex;
      flex-wrap: wrap;
      border: 1px solid #e8e8e8;
      .goods-box {
        border-right: 1px solid #e8e8e8;
        border-bottom: 1px solid #e8e8e8;
      }
      .goods-box:nth-child(5n) {
        border-right: none;
        width: 238px;
      }
    }
    .el-pagination {
      display: flex;
      justify-content: center;
      margin: 30px 0 60px 0;
      ::v-deep button:hover {
        color: #00AEEB;
      }
      ::v-deep button {
        width: 34px;
        height: 34px;
        border: 1px solid #ddd;
        padding: 0;
        min-width: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 4px;
        i {
          margin-left: 1px;
        }
      }
      ::v-deep .el-pager {
        li {
          width: 34px;
          height: 34px;
          padding: 0;
          border: 1px solid #ddd;
          min-width: 0;
          font-size: 12px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #333333;
          line-height: 32px;
          margin: 0 4px;
        }
        li.active {
          color: #00AEEB;
          border-color: #00AEEB;
        }
        .more {
          border: none;
          margin: 0;
          width: 26px;
        }
      }
      ::v-deep button:hover {
        color: #00AEEB;
      }
    }
  }
}
</style>