<template>
  <div class="goods-box" ref="goods">
    <div
      class="goods-pic"
      @click="
        goGoodsDetails(
          information.enterpriseId,
          information.barcode,
          information.commodityCode
        )
      "
    >
      <img
        v-lazy="information.filePath"
        :title="information.commodityCode"
        :key="information.filePath"
      />
    </div>
    <h3>
      ￥{{
        preferentialTime
          ? information.preferentialTradePrice
          : information.tradePrice | test
      }}<i>／{{ information.prickle }}</i>
      <span
        >{{ information.province | province
        }}{{ information.city | city }}</span
      >
    </h3>
    <p
      :class="[isStore ? 'goods-store-name' : 'goods-name']"
      @click="
        goGoodsDetails(
          information.enterpriseId,
          information.barcode,
          information.commodityCode
        )
      "
      :title="information.commodityName"
    >
      {{ information.commodityName }}
    </p>
    <p
      class="shop-name"
      @click="
        goGoodsDetails(information.enterpriseId, information.commodityCode)
      "
      v-if="!isStore && !isStoreAllGoods"
      :title="information.orgName"
    >
      <svg-icon icon-class="ic-shop" />{{ information.orgName }}
    </p>
  </div>
</template>

<script>
import Cookies from "js-cookie";
import { getTitle, setTitle } from "@/utils/cookie";
import { setBreadcrumb } from "@/utils/breadcrumb";

export default {
  name: "goods",
  props: ["information"],
  components: {},
  data() {
    return {
      isStore: false, //是否在店铺主页
      isHome: false, //是否在主页
      isActivities: false, //是否在活动页
      isStoreAllGoods: false, //是否在店铺的所有商品、单品
      preferentialTime: true, //是否在优惠期间
      price: 0, //展示的特惠价格
      regularPrice: 0,
    };
  },
  watch: {
    $route: {
      handler: function (val, oldVal) {
        if (val.name === "home-page" || val.name === "store-category") {
          this.isStore = true;
          this.$nextTick(function () {
            this.$refs.goods.style.height = "304px";
          });
        } else if (val.path === "/Home") {
          this.isHome = true;
        } else if (val.name === "activities-column") {
          // this.isActivities = true;
        } else if (val.query.storeCode != undefined) {
          this.isStoreAllGoods = true;
        }
      },
      deep: true,
      immediate: true,
    },
    information: {
      handler: function (val, oldVal) {
        this.preferential();
      },
      immediate: true,
    },
  },

  filters: {
    //处理函数
    test(num) {
      if (num === null) {
        return "-";
      } else if (String(Number(num)).indexOf(".")) {
        // return num;
        return num.toFixed(2);
      } else {
        return num;
      }
      // console.log(value);
      // return value + ".00";
      // console.log(value.toString().split(".")[1].length);
    },
    province(province) {
      return province.split("省")[0].split("市")[0];
      // console.log(province.split("省")[0].split("市")[0]);
    },
    city(city) {
      return city.split("省")[0].split("市")[0];
      // console.log(province.split("省"));
      // console.log(city.split("市")[0]);
    },
  },
  created() {
    // Cookies.get("title") && console.log(JSON.parse(Cookies.get("title")));
  },
  methods: {
    //企业id,商品码,单品id
    goGoodsDetails(enterpriseId, barcode, commodityCode) {
      //店铺页商品
      if (this.isStore) {
        this.$router.push({
          path: `/Store/HomePage/${this.$route.params.id}/GoodsDetails/${barcode}`,
          query: { enterpriseId: this.$route.query.enterpriseId },
        });

        //店铺页所有商品
      } else if (this.isStoreAllGoods) {
        this.$router.push({
          path: `/Store/HomePage/${this.$route.query.storeCode}/GoodsDetails/${barcode}`,
          query: { enterpriseId: this.$route.query.enterpriseId },
        });
      } else {
        if (Cookies.get("title")) {
          setBreadcrumb("关联店铺", this.$route.path);
          // let t = getTitle();
          // t.name.push(this.information.commodityName);
          // t.path = [];
          // t.path.push(this.$route.path);
          // Cookies.remove("title");
          // setTitle(t);
          // setBreadcrumb(this.information.commodityName, this.$route.path);
        } else if (this.$route.name === "home") {
          setTitle({ name: ["推荐商品"], path: ["/home"] });
        }
        this.$router.push({
          name: "store-list",
          params: { id: enterpriseId },
          query: { barcode: barcode, commodityCode: commodityCode },
        });
      }
    },
    // //判断价格
    // pricesDetermine() {
    //   if (this.preferentialTime) {
    //     //在特惠时间
    //     this.price = this.information.preferentialTradePrice; //特惠批发价
    //     this.regularPrice = this.information.tradePrice; //普通批发价
    //   } else {
    //     //不在特惠时间
    //     this.price = this.information.tradePrice; //普通批发价
    //   }
    // },
    preferential() {
      let now = new Date();
      let m = now.getTime(); //当前时间戳
      if (this.information.preferentialEndTime === null) {
        this.preferentialTime = false;
      } else {
        let preferential = m - this.information.preferentialEndTime; //计算优惠时间是否超过当前时间

        if (preferential > 0) {
          this.preferentialTime = false;
        }
      }

      // this.pricesDetermine();
    },
  },
};
</script>
<style lang="scss" scoped>
.goods-box:hover {
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.12);
  z-index: 1;
  .goods-pic div {
    display: block;
  }
}
.goods-box {
  width: 240px;
  height: 322px;
  padding: 20px;
  background: #fff;
  .goods-pic {
    position: relative;
    width: 200px;
    height: 200px;
    cursor: pointer;
    img {
      width: 200px;
      height: 200px;
    }
    div {
      position: absolute;
      bottom: 0;
      display: none;
      width: 208px;
      height: 28px;
      background: rgba(0, 0, 0, 0.45);
      opacity: 1;
      text-align: center;
      line-height: 28px;
      p {
        font-size: 12px;
        color: #ffffff;
      }
    }
  }
  h3 {
    line-height: 26px;
    font-size: 20px;
    margin: 8px 0 6px 0;
    font-weight: 400;
    color: #fc2c22;
    i {
      font-size: 16px;
      font-weight: 400;
      line-height: 21px;
      color: #fc2c22;
    }
    span {
      float: right;
      font-size: 12px;
      font-family: PingFang SC;
      line-height: 20px;
      margin-top: 6px;
      color: #bbbbbb;
      line-height: 16px;
      color: #bbbbbb;
      opacity: 1;
    }
  }
  .goods-name {
    width: 208px;
    height: 20px;
    font-size: 14px;
    line-height: 19px;
    color: #333333;
    margin-bottom: 6px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    cursor: pointer;
  }
  .goods-store-name {
    font-size: 14px;
    line-height: 20px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: #333333;
    cursor: pointer;
  }
  .goods-name:hover {
    color: #00aeeb;
  }
  .shop-name {
    width: 200px;
    height: 16px;
    font-size: 12px;
    color: #888888;
    cursor: pointer;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    .svg-icon {
      font-size: 12px;
      margin-right: 4px;
    }
  }
  .shop-name:hover {
    color: #00aeeb;
    .svg-icon {
      color: #00aeeb;
    }
  }
}
</style>