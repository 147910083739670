var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"goods",staticClass:"goods-box"},[_c('div',{staticClass:"goods-pic",on:{"click":function($event){return _vm.goGoodsDetails(
        _vm.information.enterpriseId,
        _vm.information.barcode,
        _vm.information.commodityCode
      )}}},[_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(_vm.information.filePath),expression:"information.filePath"}],key:_vm.information.filePath,attrs:{"title":_vm.information.commodityCode}})]),_c('h3',[_vm._v(" ￥"+_vm._s(_vm._f("test")(_vm.preferentialTime ? _vm.information.preferentialTradePrice : _vm.information.tradePrice))),_c('i',[_vm._v("／"+_vm._s(_vm.information.prickle))]),_c('span',[_vm._v(_vm._s(_vm._f("province")(_vm.information.province))+_vm._s(_vm._f("city")(_vm.information.city)))])]),_c('p',{class:[_vm.isStore ? 'goods-store-name' : 'goods-name'],attrs:{"title":_vm.information.commodityName},on:{"click":function($event){return _vm.goGoodsDetails(
        _vm.information.enterpriseId,
        _vm.information.barcode,
        _vm.information.commodityCode
      )}}},[_vm._v(" "+_vm._s(_vm.information.commodityName)+" ")]),(!_vm.isStore && !_vm.isStoreAllGoods)?_c('p',{staticClass:"shop-name",attrs:{"title":_vm.information.orgName},on:{"click":function($event){return _vm.goGoodsDetails(_vm.information.enterpriseId, _vm.information.commodityCode)}}},[_c('svg-icon',{attrs:{"icon-class":"ic-shop"}}),_vm._v(_vm._s(_vm.information.orgName)+" ")],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }